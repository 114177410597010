<script setup lang="ts">
import Flickity from "flickity";

const flickityInstance = ref<Flickity | null>(null);

const initializeFlickity = () => {
	flickityInstance.value = new Flickity(".group-wrap", {
		// options, defaults listed

		accessibility: false,
		// enable keyboard navigation, pressing left & right keys

		adaptiveHeight: false,
		// set carousel height to the selected slide

		autoPlay: true,
		// advances to the next cell
		// if true, default is 3 seconds
		// or set time between advances in milliseconds
		// i.e. `autoPlay: 1000` will advance every 1 second

		cellAlign: "center",
		// alignment of cells, 'center', 'left', or 'right'
		// or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)

		cellSelector: undefined,
		// specify selector for cell elements

		contain: false,
		// will contain cells to container
		// so no excess scroll at beginning or end
		// has no effect if wrapAround is enabled

		draggable: false,
		// enables dragging & flicking
		// if at least 2 cells

		dragThreshold: 3,
		// number of pixels a user must scroll horizontally to start dragging
		// increase to allow more room for vertical scroll for touch devices

		freeScroll: true,
		// enables content to be freely scrolled and flicked
		// without aligning cells

		friction: 0.2,
		// smaller number = easier to flick farther

		groupCells: false,
		// group cells together in slides

		initialIndex: 0,
		// zero-based index of the initial selected cell

		lazyLoad: false,
		// enable lazy-loading images
		// set img data-flickity-lazyload="src.jpg"
		// set to number to load images adjacent cells

		percentPosition: false,
		// sets positioning in percent values, rather than pixels
		// Enable if items have percent widths
		// Disable if items have pixel widths, like images

		prevNextButtons: false,
		// creates and enables buttons to click to previous & next cells

		pageDots: false,
		// create and enable page dots

		resize: true,
		// listens to window resize events to adjust size & positions

		rightToLeft: false,
		// enables right-to-left layout

		setGallerySize: false,
		// sets the height of gallery
		// disable if gallery already has height set with CSS

		watchCSS: false,
		// watches the content of :after of the element
		// activates if #element:after { content: 'flickity' }

		wrapAround: true,
		// at end of cells, wraps-around to first for infinite scrolling
	});
};

const destroyFlickity = () => {
	if (flickityInstance.value) {
		flickityInstance.value.destroy();
		flickityInstance.value = null;
	}
};

onMounted(() => {
	initializeFlickity();
});

onBeforeUnmount(() => {
	destroyFlickity();
});
</script>

<template>
	<div class="section">
		<div class="group-wrap">
			<div class="group a">
				<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
				<li><img src="../../assets/partners/Xiaom.png" alt="" /></li>
				<li><img src="../../assets/partners/CPL.png" alt="" /></li>
				<div class="carousel__snapper"></div>
			</div>

			<div class="group b">
				<li><img src="../../assets/partners/LinkGroup.png" alt="" /></li>
				<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
				<li><img src="../../assets/partners/CASE3D.png" alt="" /></li>
				<div class="carousel__snapper"></div>
			</div>
			<div class="group c">
				<li><img src="../../assets/partners/ITAcademy.png" alt="" /></li>
				<li><img src="../../assets/partners/Fairwey.png" alt="" /></li>
				<li class="im-job">
					<img src="../../assets/partners/imjob.png" alt="" />
				</li>
				<div class="carousel__snapper"></div>
			</div>
			<div class="group d">
				<li><img src="../../assets/partners/AutoPRO.png" alt="" /></li>
				<li><img src="../../assets/partners/CPL.png" alt="" /></li>
				<li><img src="../../assets/partners/Xiaom.png" alt="" /></li>
				<div class="carousel__snapper"></div>
			</div>
			<div class="group e">
				<li class="mojofix">
					<img src="../../assets/partners/MOJO.png" alt="" />
				</li>
				<li><img src="../../assets/partners/SmartPoint.png" alt="" /></li>
				<li><img src="../../assets/partners/Rhea.png" alt="" /></li>
				<div class="carousel__snapper"></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.section {
	position: relative;

	// padding-top: 75%;
	// filter: drop-shadow(0 0 10px #0003);
	width: 350px;
	padding: 0 1rem;
	overflow: hidden;

	// *::-webkit-scrollbar {
	// 	width: 0;
	// }

	// *::-webkit-scrollbar-track {
	// 	background: transparent;
	// }

	// *::-webkit-scrollbar-thumb {
	// 	background: transparent;
	// 	border: none;
	// }

	// * {
	// 	-ms-overflow-style: none;
	// }

	.group-wrap {
		// position: absolute;
		// top: 0;
		// right: 0;
		// bottom: 0;
		// left: 0;
		// display: flex;
		// overflow-x: scroll;
		// counter-reset: item;
		// scroll-behavior: smooth;
		// scroll-snap-type: x mandatory;
		// scroll-snap-align: center;

		.group {
			width: 250px;
			height: 300px;
			position: absolute;

			// &:first-child {
			// 	margin-left: 60px;
			// }
			// &:last-child {
			// 	margin-right: 60px;
			// }
		}

		// & > .group {
		// 	position: relative;
		// 	flex: 0 0 100%;
		// 	width: 70%;
		// 	max-width: 70%;
		// 	box-sizing: content-box;

		// 	&::before {
		// 		content: "";
		// 		position: absolute;
		// 		top: 50%;
		// 		left: 50%;
		// 		transform: translate3d(-50%, -40%, 70px);
		// 		color: #fff;
		// 	}

		// 	.carousel__snapper {
		// 		position: absolute;
		// 		top: 0;
		// 		left: 0;
		// 		width: 100%;
		// 		height: 100%;
		// 		scroll-snap-align: center;
		// 		animation-name: tonext, snap;
		// 		animation-timing-function: ease;
		// 		animation-duration: 4s;
		// 		animation-iteration-count: infinite;
		// 	}

		// 	&:last-child .carousel__snapper {
		// 		animation-name: tostart, snap;
		// 	}
		// }

		:deep() {
			.flickity-slider {
				position: absolute;
				width: 100%;
				height: 100%;
			}

			.flickity-button,
			.flickity-page-dots {
				display: none;
			}
		}

		li {
			display: flex;
			align-items: center;
			justify-content: center;

			&.im-job {
				display: flex;
				height: 90px;

				img {
					height: 70%;
				}
			}
		}
	}
}

@keyframes tonext {
	75% {
		left: 0;
		opacity: 0;
	}

	95% {
		left: 100%;
		opacity: 1;
	}

	98% {
		left: 100%;
		opacity: 1;
	}

	99% {
		left: 0;
		opacity: 0;
	}
}

@keyframes tostart {
	75% {
		left: 0;
		opacity: 0;
	}

	95% {
		left: -300%;
		opacity: 1;
	}

	98% {
		left: -300%;
		opacity: 1;
	}

	99% {
		left: 0;
		opacity: 0;
	}
}

@keyframes snap {
	96% {
		scroll-snap-align: center;
	}

	97% {
		scroll-snap-align: none;
	}

	99% {
		scroll-snap-align: none;
	}

	100% {
		scroll-snap-align: center;
	}
}
</style>
