<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="section-wrapper">
		<div class="dark-background">
			<!-- <inline-svg v-if="isFill" class="clock fill" :src="clockSvgFill" alt="" />
			<inline-svg v-else class="clock stroke" :src="clockSvgStroke" alt="" /> -->

			<div class="clock-wrap">
				<inline-svg class="glow-img" :src="glowImg"></inline-svg>
				<div class="spinner-image">
					<img class="a a1" :src="frontSpinner" />
					<img class="a a2" :src="frontSpinner" />
					<img class="a a3" :src="frontSpinner" />
					<img :src="backSpinner" alt="" class="b" />
				</div>
				<inline-svg class="clock" :src="clockImg"></inline-svg>
			</div>
		</div>
		<!-- <div style="height: 100vh"></div> -->
		<div class="page">
			<div class="top">
				<h1 class="header">
					<div class="deli">
						<span>Delivering</span>
						<span class="mobile">software</span>
					</div>
					<div class="mobile row">
						<span style="margin-right: 8px">projects</span>
						<div class="underlined">
							<span class="intime">on time,</span>
							<inline-svg :src="underlineSvg" />
							<div class="image-overlay"></div>
						</div>
					</div>
					<span class="soft-pro">
						<span class="desktop">software projects</span>
						<!-- <span class="mobile">projects</span> -->
					</span>
					<div class="underlined desktop">
						<span class="intime">on time,</span>
						<inline-svg :src="underlineSvg" />
						<div class="image-overlay"></div>
					</div>
					<div class="small-title">every time!</div>
				</h1>
				<h3 class="subheader">Start your digital journey.</h3>
				<router-link
					class="btn"
					:to="{ name: 'QuoteContact' }"
					@click="store.trackQuoteCTA()"
					>Get a free quote</router-link
				>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import glowImg from "@/assets/images/upgraded/glow.svg";
import frontSpinner from "@/assets/images/main/front part spinner.svg";
import backSpinner from "@/assets/images/main/back spinner.svg";
// import clockSvgFill from "@/assets/images/clockfill.svg";
// import clockSvgStroke from "@/assets/images/clockstroke.svg";
import clockImg from "@/assets/images/clockImg2.svg";
import underlineSvg from "@/assets/images/underline.svg";
import { useDefaultStore } from "@/store";

// const isFill = ref(true);
const store = useDefaultStore();

// function scrollToBottom() {
// 	window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
// }
</script>

<style lang="scss" scoped>
.section-wrapper {
	padding-top: ($top-page-nav-height + 150px);
	position: relative;
	padding-bottom: 50px;
	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.03) 0%,
		rgba(13, 16, 21, 0) 5%
	);
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	// background-color: yellow;

	.small-title {
		font-size: 60px;
		font-weight: 400;
		top: 10px;
		position: relative;
		left: 350px;

		@media screen and (width < 768px) {
			font-size: 26px;
			text-align: right;
			margin-top: -5px;
			left: 0;
			top: 0;
		}
	}

	.spinner-image {
		// $size: 150px;

		// width: $size;
		// min-width: $size;
		// height: $size;
		user-select: none;
		pointer-events: none;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;

		@media screen and (width < 768px) {
			top: 0;
		}

		img {
			filter: blur(2px);
		}

		img.a {
			position: absolute;
			animation-name: spin;
			animation-duration: 5000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;

			// transform: translate(-50%, -50%);
			// top: 50%;
			// left: 50%;

			// animation-delay: ;
			width: 100%;
			height: 100%;

			&.a1 {
				animation-duration: 5000ms;
			}

			&.a2 {
				animation-duration: 4000ms;
				animation-direction: reverse;
			}

			&.a3 {
				animation-duration: 6000ms;
				animation-direction: reverse;
			}
		}

		img.b {
			// $pos: 15px;

			position: absolute;
			width: 100%;
			height: 100%;

			// left: $pos;
			// top: $pos;
			// width: 80%;
			// height: 80%;
		}

		.center-text {
			position: absolute;
			top: 48%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #fff;
			text-align: center;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			.text-up {
				font-size: 25px;
				font-weight: bold;
			}

			.text-down {
				font-size: 15px;
			}
		}

		@media screen and (width >= 768px) {
			// $size: 80%;

			// width: $size;
			// min-width: $size;
			// height: $size;

			img.a {
				width: unset;
				height: unset;
			}

			img.b {
				// $pos: 15px;

				// left: $pos;
				// top: $pos;
				width: unset;
				height: unset;
			}

			.center-text {
				.text-up {
					font-size: 50px;
				}

				.text-down {
					font-size: 20px;
				}
			}
		}
	}

	@for $i from 1 through 8 {
		&:nth-child(#{$i}) {
			.spinner-image {
				img {
					animation-delay: 300ms * $i;
				}
			}
		}
	}

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}

	@keyframes spin-alt {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}

.glow-img {
	position: absolute;
	margin: auto;
	max-width: 100%;
	top: -176px;

	@media screen and (width < 768px) {
		height: 100%;
		top: 0;
	}
}

.dark-background {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 100%;

	.clock-wrap {
		$size: 580px;

		display: flex;
		position: absolute;
		transform: translate(-53%, -4%);

		// left: 18vw;
		// top: 10vh;
		width: $size;
		height: $size;
		align-items: center;
		justify-content: center;

		@media screen and (width< 768px) {
			$size: 50vw;

			transform: translate(-23%, 17%);
			width: $size;
			height: $size;
		}
	}

	.clock {
		position: absolute;
		width: 350px;

		:deep() {
			&.fill {
				path {
					fill: #292e34;
				}
			}

			&.stroke {
				path {
					stroke: #292e34;
				}
			}
		}

		@media screen and (width < 768px) {
			width: 40vw;
			height: 100%;
		}
	}
}

// @keyframes dash {
// 	0% {
// 		stroke-dashoffset: 1320;
// 	}

// 	100% {
// 		stroke-dashoffset: 0;
// 		stroke: #3f3f3f;
// 	}

// 	0%,
// 	71% {
// 		fill: none;
// 	}

// 	75%,
// 	100% {
// 		fill: none;
// 	}
// }

.top {
	position: relative;
	height: 469px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	z-index: 1;

	// white-space: nowrap;

	.header {
		font-weight: 600;
		font-size: 88px;
		line-height: 103px;
		text-align: center;
		animation: appear1 1s 0s forwards;
		position: absolute;
		top: 0;
		margin-top: 50px;
		opacity: 0;

		.deli {
			font-size: 50px;
			text-align: left;
			font-weight: 400;
			line-height: 57px;
			display: flex;
			gap: 7px;

			@media screen and (width < 768px) {
				font-size: 22px;
				text-align: left;
				line-height: 30px;
			}
		}

		.mobile.row {
			text-align: left;
			font-size: 49.2px;

			@media screen and (width > 768px) {
				display: none;
			}
		}

		.soft-pro {
			margin-right: 20px;

			@media screen and (width < 768px) {
				font-size: 50px;

				.mobile {
					margin-right: auto;
					display: block;
				}

				// margin: 0 7px;
			}
		}

		.intime {
			@media screen and (width < 768px) {
				// font-size: 50px;
				text-align: right;
			}
		}

		// display: flex;
		// gap: 5px;

		.underlined {
			font-weight: 600;
			position: relative;

			// white-space: nowrap;
			display: inline-block;
			margin-bottom: -38px;
			overflow: hidden;
			padding-bottom: 15px;

			span {
				z-index: 1;
			}

			svg {
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				height: auto;
				z-index: -1;
				background: transparent;

				:deep() {
					path {
						stroke: $second-app-color;
					}
				}
			}

			.image-overlay {
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				z-index: 0;
				height: 35px;
				background: var(--es-background-color);
				animation: appear3 0.5s 1s forwards;
				margin-left: 0;
			}
		}
	}

	.subheader {
		font-weight: 400;
		font-size: 24px;
		line-height: 26px;
		text-align: center;
		animation: appear1 1s 0.3s forwards;
		top: 360px;
		opacity: 0;
		position: relative;
		left: 290px;

		@media screen and (width < 768px) {
			left: 0;
			top: 0;

			// margin-left: auto;
			text-align: right;
		}
	}

	.btn {
		animation: appear2 1s 0.6s forwards;
		margin-bottom: -50px;
		opacity: 0;
		background: $app-color;
		position: relative;
		left: 290px;
		bottom: -10px;

		// bottom: 30px;

		@media screen and (width < 768px) {
			left: 0;
			top: 10px;

			// margin-left: auto;
		}
	}

	.btn:hover {
		opacity: 0.9 !important;
	}
}

.mobile {
	@media screen and (width > 768px) {
		display: none !important;
	}
}

.desktop {
	@media screen and (width <= 768px) {
		display: none !important;
	}
}

@media screen and (width <= 768px) {
	.section-wrapper {
		padding-top: ($top-page-nav-height);
		background-size: contain;
	}

	.dark-background {
		height: 300px;
	}

	.top {
		height: 350px;
		width: 342px;

		.header {
			font-size: 32px;
			line-height: 40px;
			margin-top: 15px;
			width: 100%;

			.underlined {
				padding-bottom: 20px;
				margin-bottom: -26px;

				svg,
				.image-overlay {
					bottom: 3px;
				}
			}
		}

		.subheader {
			font-size: 15px;
			line-height: 26px;
			top: 270px;
		}
	}

	.image-wrapper {
		.main-image {
			height: 400px;
		}
	}
}
</style>
