import { defineStore } from "pinia";

export const useDefaultStore = defineStore("main", () => {
	const quoteFeatures = ref<number[]>([]);
	const quoteDatePickerVal = ref("");
	const quoteSelectedDesign = ref("not_selected");
	const quoteName = ref<string | null>("");
	const quoteLastName = ref("");
	const quoteEmail = ref<string | null>("");
	const quotePhone = ref<string | null>("");
	const quoteComment = ref("");
	const quoteReferrer = ref<string | null>(null);
	const cookieConsent = ref(false);
	const priceImpactFeatures = ref(0);
	const priceImpact = ref("low");
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// const leadsDomain = "442c123.mars-t.mars-hosting.com"; // dev
	const leadsDomain = "435t123.mars2.mars-hosting.com"; // prod
	const leadsRootApi = `https://${leadsDomain}/api/`;

	function resetForm() {
		console.log("resetovano");
		quoteFeatures.value = [];
		quoteDatePickerVal.value = "";
		quoteSelectedDesign.value = "not_selected";
		quoteName.value = null;
		quoteLastName.value = "";
		quoteEmail.value = null;
		quotePhone.value = null;
		quoteComment.value = "";
		priceImpact.value = "low";
	}

	// const isFormValid = computed(() => {
	// 	// const isNameValid = quoteName.value.trim() !== "";
	// 	// const isLastNameValid = quoteLastName.value.trim() !== "";
	// 	const isEmailValid = emailRegex.test(quoteEmail.value.trim());

	// 	return /*isNameValid && isLastNameValid &&*/ isEmailValid;
	// });

	const isFormValid = computed(() => {
		if (!quoteEmail.value && !quotePhone.value) return false;
		if (quoteEmail.value) {
			const isEmailValid = emailRegex.test(quoteEmail.value.trim());
			return isEmailValid;
		} else {
			return true;
		}
	});

	function trackQuoteCTA() {
		// Track the CTA click
		fbq("trackCustom", "ClickGetQuote");
	}

	return {
		emailRegex,
		quoteFeatures,
		quoteDatePickerVal,
		quoteSelectedDesign,
		quoteName,
		quoteLastName,
		quoteEmail,
		quotePhone,
		quoteComment,
		resetForm,
		isFormValid,
		quoteReferrer,
		cookieConsent,
		priceImpact,
		priceImpactFeatures,
		trackQuoteCTA,
		leadsDomain,
		leadsRootApi,
	};
});
