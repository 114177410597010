import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import TeamPage from "../views/TeamPage.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Projects from "@/views/ProjectsPage.vue";
import Technology from "@/views/TechnologyView.vue";
import CaseStudies from "@/views/CaseStudies.vue";
import SuggestionsSection from "@/views/SuggestionsSection.vue";
import Quote from "@/views/QuotePage.vue";
import QuoteStart from "@/views/QuoteStart.vue";
import QuoteFeatures from "@/views/QuoteFeatures.vue";
import QuoteDate from "@/views/QuoteDate.vue";
import QuoteDesign from "@/views/QuoteDesign.vue";
import QuoteContact from "@/views/QuoteContact.vue";
import QuoteDone from "@/views/QuoteDone.vue";
import ProbaView from "@/views/ProbaView.vue";
import { useDefaultStore } from "@/store";

const routes = [
	{
		path: "/",
		name: "Home",
		component: HomePage,
	},
	{
		path: "/team",
		name: "TeamPage",
		component: TeamPage,
	},
	{
		path: "/projects",
		name: "Projects",
		component: Projects,
	},
	{
		path: "/proba",
		name: "Proba",
		component: ProbaView,
	},
	{
		path: "/quote",
		name: "Quote",
		component: Quote,
		children: [
			// {
			// 	path: "start",
			// 	name: "QuoteStart",
			// 	component: QuoteStart,
			// },
			// {
			// 	path: "features",
			// 	name: "QuoteFeatures",
			// 	component: QuoteFeatures,
			// },
			// {
			// 	path: "date",
			// 	name: "QuoteDate",
			// 	component: QuoteDate,
			// },
			// {
			// 	path: "design",
			// 	name: "QuoteDesign",
			// 	component: QuoteDesign,
			// },
			{
				path: "",
				name: "QuoteContact",
				component: QuoteContact,
			},
			{
				path: "done",
				name: "QuoteDone",
				component: QuoteDone,
				beforeEnter: (to, from, next) => {
					if (!useDefaultStore().isFormValid) {
						next({ name: "QuoteContact" });
					} else {
						next();
					}
				},
			},
		],
	},
	{
		path: "/technologies",
		name: "Technology",
		component: Technology,
	},
	{
		path: "/casestudies",
		name: "Case Studies",
		component: CaseStudies,
	},
	// {
	// 	path: "/suggestions",
	// 	name: "SuggestionsSection",
	// 	component: SuggestionsSection,
	// },
	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

router.afterEach((to, from) => {
	// Map routes to Meta Pixel events
	if (to.path === "/") {
		fbq("track", "PageView");
		fbq("track", "ViewContent");
	} else {
		fbq("track", "PageView");
	}
});

export default router;
