<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="first-step-wrapper">
		<h2>You're done!</h2>
		<h3>
			Thank you for your interest in learning more about the website creation
			process!
		</h3>
		<h3>
			Determining a price for a website isn't as straight forward as many people
			think. Someone needs to look over your request and provide you with the
			<span>best deal possible</span>.
		</h3>
		<h3>
			A representative from our team will contact you as soon as your quote is
			ready (usually less than one business day).
		</h3>
		<router-link class="btn" :to="{ name: 'Home' }">Home page</router-link>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";

onMounted(() => {
	animateElementsOnScroll(
		".first-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , h3, a",
	);
});
</script>

<style lang="scss" scoped>
.first-step-wrapper {
	max-width: 800px;
	margin: 0 auto;
	position: relative;

	h2 {
		text-align: center;
		font-size: 32px;
		line-height: 40px;
		font-style: normal;
		font-weight: 600;
		margin: 0 auto 48px;
		color: $app-color;
	}

	h3 {
		text-align: left;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		max-width: 800px;
		margin: 0 auto 24px;

		span {
			color: $second-app-color;
			font-weight: bold;
		}
	}

	.btn {
		margin: 48px auto;
		width: 200px;
		z-index: 2;
	}
}

@media screen and (min-width: 768px) {
	.first-step-wrapper {
		h2 {
			font-size: 42px;
			line-height: 48px;
			text-align: center;
		}

		h3 {
			font-size: 25px;
		}
	}
}
</style>
