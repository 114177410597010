<template>
	<div class="marquee">
		<ul class="marquee-content desktop">
			<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
			<li><img src="../../assets/partners/Xiaom.png" alt="" /></li>
			<li><img src="../../assets/partners/CPL.png" alt="" /></li>
			<li><img src="../../assets/partners/LinkGroup.png" alt="" /></li>
			<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
			<li><img src="../../assets/partners/CASE3D.png" alt="" /></li>
			<li><img src="../../assets/partners/ITAcademy.png" alt="" /></li>
			<li><img src="../../assets/partners/Fairwey.png" alt="" /></li>
			<li style="padding: 20px 0">
				<img src="../../assets/partners/imjob.png" alt="" />
			</li>
			<li class="mojofix">
				<img src="../../assets/partners/MOJO.png" alt="" />
			</li>
			<li><img src="../../assets/partners/SmartPoint.png" alt="" /></li>
			<li><img src="../../assets/partners/Rhea.png" alt="" /></li>
			<li><img src="../../assets/partners/AutoPRO.png" alt="" /></li>

			<!-- overlaping pictures  -->
			<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
			<li><img src="../../assets/partners/Xiaom.png" alt="" /></li>
			<li><img src="../../assets/partners/CPL.png" alt="" /></li>
			<li><img src="../../assets/partners/LinkGroup.png" alt="" /></li>
			<li><img src="../../assets/partners/Vizim.png" alt="" /></li>
			<li><img src="../../assets/partners/CASE3D.png" alt="" /></li>
		</ul>

		<ul class="marquee-content mobile">
			<mobile-sponsors></mobile-sponsors>
		</ul>
	</div>
</template>
<script setup></script>
<style scoped lang="scss">
$numberofelements: 13;

.marquee {
	//   background-color: black;
	width: 100vw;
	display: flex;
	overflow: hidden;

	.marquee-content {
		height: 100%;
		list-style: none;
		min-height: 300px;

		// overflow: hidden;
		grid-template-columns: 1fr;
		pointer-events: none;
		user-select: none;
		margin: auto;

		&.mobile {
			display: flex;
		}

		&.desktop {
			display: none;
		}

		@media screen and (width >= 768px) {
			grid-template-rows: 100px;
			grid-template-columns: auto;
			animation: scrolling 40s linear infinite;
			grid-auto-flow: column dense;
			min-height: auto;
			margin: unset;

			&.mobile {
				display: none;
			}

			&.desktop {
				display: grid;
			}

			> * {
				height: unset;
			}
		}

		li {
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--marquee-item-width);
			flex-shrink: 0;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.mojofix {
			padding-top: 10px;
		}
	}
}

@keyframes scrolling {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(
			calc(-1 * ($numberofelements * var(--marquee-item-width)))
		);
	}
}

@media screen and (width <= 1000px) {
	.marquee {
		--marquee-item-width: 40vw;
	}
}
</style>
